import $ from "jquery";

$(function () {
    let lastVidbutton = null;
    $(".vidbutton").on("click", function () {
        $(this).toggleClass("hidden");
        const nextVideo = $(this).next("video");
        if (nextVideo.prop("paused")) {
            nextVideo.trigger("play");
            if (lastVidbutton) {
                $(lastVidbutton).toggleClass("hidden");
                const lastPlayingVideo = $(lastVidbutton).next("video");
                lastPlayingVideo.trigger("pause");
            }
            lastVidbutton = this;
        } else {
            nextVideo.trigger("pause");
            lastVidbutton = null;
        }
    });
});
