import $ from "jquery";
import Swiper from 'swiper/bundle';

$(function() {
    $(".bl-aktuelllist").each(function() {
        const $bl = $(this);
        initBlock($bl);
    });
});

function initBlock($bl) {
    const swiper = new Swiper($bl.find('.swiper').get(0), {
        //loop: true,
        rewind: true,
        autoHeight: true,
        
        pagination: {
          el: $bl.find('.swiper-pagination').get(0),
          clickable: true
        },
      
        navigation: {
          nextEl: $bl.find('.button-next').get(0),
          prevEl: $bl.find('.button-prev').get(0),
        },
  
        /*
        speed: 1500,
        autoplay: {
          delay: 4000
        }
        */
  
      });
}

