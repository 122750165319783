//npm install photoswipe
//npm install photoswipe-dynamic-caption-plugin
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';

import $ from "jquery";

function enhanceGallery() {
    const lightbox = new PhotoSwipeLightbox({
        gallery: '.bl-gallery',
        children: 'a',
        showHideAnimationType: 'zoom', //'fade'
        //showHideOpacity:true,
        //getThumbBoundsFn:false,
        //pswpModule: () => import('photoswipe'),
        pswpModule: PhotoSwipe,
        paddingFn: (viewportSize) => {
            return {
              top: 15, bottom: 15, left: 15, right: 15
            }
        },
        bgOpacity: 0.9
    });
    /*
    const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
        type: 'below'
    });
    */
    lightbox.init();
}

/*
function enhanceTextWithImage() {
    if($(".textwithimage").length == 0) {
        return;
    }

    const lightbox = new PhotoSwipeLightbox({
        //gallery: '.textwithimage', //nur zoom, kein Weiterschalten zum naechsten Bild moeglich
        //children: '.ctrimage a',
        gallery: 'body', //Weiterschaltung zum naechsten Bild moeglich
        children: '.textwithimage .ctrimage a',
        showHideAnimationType: 'zoom', //'fade'
        //showHideOpacity:true,
        //getThumbBoundsFn:false,
        //pswpModule: () => import('photoswipe')
        pswpModule: PhotoSwipe
    });
    lightbox.init();
}
*/

$(function() {
    enhanceGallery();
});