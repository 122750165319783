import $ from "jquery";

$(document).ready(function () {
    const $form = $("form.licenseorder");
    if($form.length == 0) {
        return;
    }
    

    const $ctrnumsentinels = $form.find(".ctrnumsentinels");
    $ctrnumsentinels.hide();

    function updateSentinelVisibility() {
        const selectedValue = $form.find("[name=SentinelsNeeded]:checked").data("showsentinelscount");
        if(selectedValue !== undefined) {
            $ctrnumsentinels.toggle(selectedValue);
        }
    }

    updateSentinelVisibility();
    $form.find("[name=SentinelsNeeded]").on("click", updateSentinelVisibility);


    //V2 ab hier (01.08.2024 online)
    const isV2 = $form.has("template.tpldurchfuehrung");
    if(!isV2) {
        return;
    }

    function addDurchfuehrung()
    {
        const $tplDurchfuehrung = $form.find("template.tpldurchfuehrung");
        const $durchfuehrungen = $form.find(".durchfuehrungen");
        $durchfuehrungen.append($tplDurchfuehrung.html());
    }

    addDurchfuehrung(); //add default first Durchfuehrung
    $form.find(".btnadddurchfuehrung").on("click", function() {
        addDurchfuehrung();
    });

    const $durchfuehrungen = $form.find(".durchfuehrungen");
    $durchfuehrungen.on("click", ".btnremovedurchfuehrung", function() {
        const $durchfuehrung = $(this).closest(".durchfuehrung");
        $durchfuehrung.remove();
    });  


    //Custom Form Validation
    //https://docs.modx.pro/en/components/fetchit/frontend/events#fetchitbefore
    document.addEventListener('fetchit:before', (e) => {
        const { form, formData } = e.detail;

        const $form = $(form);
        if($form.hasClass("licenseorder")) {
            if(!checkLicenseFormAdditional($form)) {
                e.preventDefault(); //Cancel form submission
                scrollToFirstError($form);
                return;
            }

            const durchfuehrungenInfo = collectInfoForAllDurchfuehrungen($form);
            formData.set('durchfuehrungeninfo', durchfuehrungenInfo); //Add a new field to FetchIt data
        }
    });
});

function getLanguage() {
    return $("html").attr("lang");
}

function collectInfoForAllDurchfuehrungen($form)
{
    let info = "";
    let durchfuehrungIndex = 1;
    $form.find(".durchfuehrungen").find(".durchfuehrung").each(function() {
        const $durchfuehrung = $(this);
        if(durchfuehrungIndex > 1) {
            info += "\n\n";
        }
        info += "Durchfuehrung Nr. "+durchfuehrungIndex+":\n";
        info += collectInfoForDurchfuehrung($durchfuehrung);
        durchfuehrungIndex++;
    });
    return info;
}

function collectInfoForDurchfuehrung($durchfuehrung) {
    const dateStart = formatDate($durchfuehrung.find(".datestart").get(0).valueAsDate);
    const dateEnd = formatDate($durchfuehrung.find(".dateend").get(0).valueAsDate);
    const numClasses = $durchfuehrung.find(".numclasses").val();
    const spielleiter = $durchfuehrung.find(".spielleiter").val();
    
    if(getLanguage() == "fr") {
        return "Date : "+dateStart+" - "+dateEnd+"\n"+
            "Nombre de classes : "+numClasses+"\n"+
            "Animateurs/animatrices :\n"+spielleiter;
    } else {
        return "Datum: "+dateStart+" - "+dateEnd+"\n"+
            "Anzahl Klassen: "+numClasses+"\n"+
            "Spielleiter:\n"+spielleiter;
    }
}

function formatDate(date) {
    if(!date) {
        return '';
    }

    let dd = date.getDate();
    let mm = date.getMonth() + 1; //Month starts at 0!
    const yyyy = date.getFullYear();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy;
}


//**Start Validation
function scrollToFirstError($form) {
    const firstError = $form.get(0).getElementsByClassName("error")[0];
    if(firstError) {
        //Scroll to first error
        const ypos = firstError.getBoundingClientRect().top + window.pageYOffset - window.innerHeight/3;
        window.scrollTo({top: ypos, behavior: 'smooth'});
    }
}

//additional checks for license form
function checkLicenseFormAdditional($form) {
    let validationOk = true;
    $form.find(".durchfuehrungen").find(".durchfuehrung").each(function() {
        const $durchfuehrung = $(this);
        if(!checkLicenseFormDurchfuehrung($durchfuehrung)) {
            validationOk = false;
        }
    });
    return validationOk;
}

function checkLicenseFormDurchfuehrung($durchfuehrung) {
    let validationOk = true;
    let $field;
    const language = getLanguage();
    const notEmptyMessage = (language=="fr" ? "Ce champ est obligatoire." : "Dieses Feld muss ausgefüllt werden.");
    
    //Check fields not empty
    $field = $durchfuehrung.find(".datestart");
    if($field.val() == "") {
        setFieldError($field, notEmptyMessage);
        validationOk = false;
    } else {
        clearFieldError($field);
    }

    $field = $durchfuehrung.find(".dateend");
    if($field.val() == "") {
        setFieldError($field, notEmptyMessage);
        validationOk = false;
    } else {
        clearFieldError($field);
    }

    $field = $durchfuehrung.find(".numclasses");
    if($field.val() == "") {
        setFieldError($field, notEmptyMessage);
        validationOk = false;
    } else {
        clearFieldError($field);
    }

    $field = $durchfuehrung.find(".spielleiter");
    if($field.val() == "") {
        setFieldError($field, notEmptyMessage);
        validationOk = false;
    } else {
        clearFieldError($field);
    }

    //Check end date not before start date
    const $datestart = $durchfuehrung.find(".datestart");
    const $dateend = $durchfuehrung.find(".dateend");
    const dateStart = $datestart.get(0).valueAsDate;
    const dateEnd = $dateend.get(0).valueAsDate;
    if(dateStart != null && dateEnd != null && dateEnd < dateStart) {
        const endNotBeforeStartMessage = (language=="fr" ? "La date de fin ne doit pas être antérieure à la date de début." : "Das Enddatum darf nicht vor dem Startdatum liegen.");
        setFieldError($dateend, endNotBeforeStartMessage);
        validationOk = false;
    }

    return validationOk;
}

function findErrorContainer($field) {
    return $field.prev(".input-error");
}

function setFieldError($field, errorMsg) {
    const $errorContainer = findErrorContainer($field);
    $errorContainer.text(errorMsg);
    if(errorMsg.length > 0) {
        $errorContainer.addClass("error");
    } else {
        $errorContainer.removeClass("error");
    }
}

function clearFieldError($field) {
    setFieldError($field, "");
}

//**End Validation