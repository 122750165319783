import $ from "jquery";
import Swiper from 'swiper/bundle';

$(function() {
    $(".bl-logocarousel").each(function() {
        const $bl = $(this);
        initBlock($bl);
    });
});

function initBlock($bl) {
    const swiper = new Swiper($bl.find('.swiper').get(0), {
        //loop: true,
        //rewind: true,
        //autoHeight: true,
        slidesPerView: 4,
        centerInsufficientSlides: true,
        spaceBetween: 20,
        
        pagination: {
          el: $bl.find('.swiper-pagination').get(0),
          clickable: true
        },

        /*
        navigation: {
          nextEl: $bl.find('.button-next').get(0),
          prevEl: $bl.find('.button-prev').get(0),
        },
        */
  
        /*
        speed: 1500,
        autoplay: {
          delay: 4000
        }
        */
  
    });
}

