import $ from "jquery";

$(function() {
    $(".at-colorbox").each(function() {
        const $at = $(this);
        initAtom($at);
    });
});

function initAtom($at) {
    if($at.find(".ctrlink").length == 0) {
        //Box contains no link => do not make entire box clickable
        //example: https://dev.wirtschaftsbildung.pixmill.ch/beiraete.html
        return;
    }
    if($at.find(".text a").length > 0) {
        //Text contains link => do not make entire box clickable
        //example: https://dev.wirtschaftsbildung.pixmill.ch/wirtschaftswochen.html
        return;
    }

    $at.addClass("hashover");
    $at.css("cursor", "pointer");
    $at.on("click", function() {
        const linkurl = $at.find(".ctrlink a").attr("href");
        window.location.href = linkurl;
    });
}

