//FetchIt redirect handling
document.addEventListener('fetchit:success', (e) => {
    const redirecturl = e.detail.form.dataset.redirect;
    if(redirecturl) {
        window.location.href = redirecturl;
    } else {
        alert("Formular erfolgreich übermittelt.");
    }
});
//FetchIt error handling
document.addEventListener('fetchit:error', (e) => {
    const form = e.detail.form;
    setTimeout(function() {
        //Wait for fetchit to display error
        const firstError = form.getElementsByClassName("error")[0];
        if(firstError) {
            //Scroll to first error
            const ypos = firstError.getBoundingClientRect().top + window.pageYOffset - window.innerHeight/3;
            window.scrollTo({top: ypos, behavior: 'smooth'});
        } else {
            alert("Beim Übermitteln des Formulars ist ein Fehler aufgetreten.");
        }
    }, 100);
});

/*
//Fetchit message displaying
document.addEventListener('DOMContentLoaded', () => {
    FetchIt.Message = {
        success(message) {
            //alert(message); //"The form has been successfully submitted"
        },
        error(message) {
            //alert(message);
        }
    };
});
*/