import $ from "jquery";

//part of library

function showSubmenu($li) {
    $li.addClass("expanded");
    $li.find("ul").first().slideDown(200);
    $li.find("a").first().addClass("expanded");
}

function hideSubmenu($li) {
    $li.removeClass("expanded");
    $li.find("ul").first().slideUp(200);
    $li.find("a").first().removeClass("expanded");
}

function toggleSubmenu($li) {
    const isExpanded = $li.hasClass("expanded");
    if(isExpanded) {
        hideSubmenu($li);
    } else {
        showSubmenu($li);
    }
}

function initAccordionMenu($menu) {
    $menu.find("li.hassubmenu").each(function() {
        const $liWithSubmenu = $(this);
        $liWithSubmenu.find("ul").hide();
    });
    $menu.on("click", "li.hassubmenu > a", function(ev) {
        const $liWithSubmenu = $(this).closest("li");
        ev.preventDefault();
        toggleSubmenu($liWithSubmenu);
    });

    //expand active menus on load
    $menu.find("li.hassubmenu.active").each(function() {
        const $activeLiWithSubmenu = $(this);
        showSubmenu($activeLiWithSubmenu);
    });
}

$(function() {
    $(".accordionmenu").each(function() {
        const $menu = $(this);
        initAccordionMenu($menu);
    });
});