import $ from "jquery";

$(function() {
    $(".accordion").on("click", ".accordion-title", function() {
        const $accitem = $(this).closest(".accordion-item");
        const isexpanded = $accitem.hasClass("expanded");

        const $details = $accitem.find(".accordion-content");
        const animspeed = 200;

        if(!isexpanded) {
            $accitem.addClass("expanded");
            $details.slideDown(animspeed);    
        } else {
            $accitem.removeClass("expanded");
            $details.slideUp(animspeed);    
        }
    });
});